import React, { useEffect, useState } from "react"
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  PlanBnr,
  Access,
  ImgList,
  InfoTable,
  FacilityPostBtns,
  FacilityDNav,
  KitchenList,
  AmenitieList,
  FaqList,
  wovnCheck,
} from '../../../components/_index';


// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  const [floorPdf, setFloorPdf] = useState('floorPlan_nishijinbettei.pdf')
  wovnCheck(function (wovnCode: string) {
    const pdffile = wovnCode !== 'ja' ? 'floorPlan_nishijin-villa_en.pdf' : 'floorPlan_nishijinbettei.pdf';
    setFloorPdf(pdffile)
  });

  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <main id="facility_d" className="nishijin-villa under">
        <div id="facility_dMv">
          <div className="mvCont">
            <p className="ico">#5</p>
            <h2 className="facility_dMvTit">京の温所 西陣別邸（プレミアム）<span>NISHIJIN-VILLA</span></h2>
            <div className="mvImg" style={{ backgroundImage: 'url(/images/facility/484c4a864745271f7f291d56b9ef4c00_1.jpg)' }}></div>
          </div>
          <FacilityDNav data={[
            {
              anchor: "#facility_dInfo",
              label: <>施設情報</>,
            },
            {
              anchor: "#facility_dAmenitie",
              label: <>アメニティ</>,
            },
            {
              anchor: "#facility_dAccess",
              label: <>アクセス</>,
            },
            {
              anchor: "#facility_dPlan",
              label: <>プラン一覧</>,
            },
            {
              anchor: "#facility_dFaq",
              label: <>よくあるご質問</>,
            },
          ]} />
        </div>
        <div id="facility_dWrap" className="wd">
          <div id="facility_dInfoWrap">
            <section id="facility_dInfoAbout">
              <h2 className="tit">建築家 中村好文と「ミナ ペルホネン」皆川明が手がける京の別邸。<br />
                未来へ継ぐ「住まい」としての京町家</h2>
              <p>もう一歩奥の京都、少し歩けば機織りの音が聞こえ、古き良き京都の街並みが今も残る西陣。<br />
                西陣織の商家として明治後期に建てられた京町家を、未来へと受け継ぐ「住まい」として中村好文がリノベーションしました。<br />
                8名様まで滞在できる「京の温所 西陣別邸」はゆったりと広く、優雅な佇まい。宿内のあちこちには「ミナ ペルホネン」のテキスタイルがあしらわれ、皆川明によるセレクトのアートピースが空間を彩ります。のんびりとした長期滞在にもおすすめです。<br />
                また、敷地内には自動車2台分の専用ガレージも。京都の奥座敷、美山や鞍馬、大原などを自動車で巡るといった、より広く、より深く京都が楽しめる拠点にもなる宿です。</p>
              <FacilityPostBtns data={[
                {
                  href: `/assets/files/facility/${floorPdf}`,
                  blank: true,
                  label: <>間取り図<small>（PDF）</small></>,
                },
                {
                  href: "https://archihatch.com/archicle/1828/",
                  blank: true,
                  label: <>3D 間取図（ARCHI HATCH）</>,
                },
                {
                  href: "/news/recommend/#nishijin-villa",
                  blank: false,
                  label: <>周辺のおすすめ</>,
                },
              ]} />
            </section>
            <section id="facility_dInfo">
              <h2 className="tit">INFORMATION</h2>
              <p className="post">京の温所 西陣別邸（プレミアム） #5<br />
                <a href="https://goo.gl/maps/39rECvwSFwV3Cam1A" target="_blank">京都市上京区浄福寺通今出川下る竪亀屋町265-1</a>
              </p>
              <InfoTable data={[
                {
                  label: <>広さ</>,
                  value: <>2階建て196平米（1棟貸切）総面積：516平米</>,
                },
                {
                  label: <>定員</>,
                  value: <>1～8名</>,
                },
                {
                  label: <>部屋</>,
                  value: <>専用ガレージ（2台分）<br />
                    &lt;1階&gt; キッチン＆ダイニング カフェスペース<br /> 和室1 和室2 庭 風呂（青森ヒバ・2シャワー） 洗面（2ボウル） 中庭デッキスペース トイレ<br />
                    &lt;2階&gt; ベッドルーム1 ベッドルーム2 ライブラリールーム シャワールーム 洗面 トイレ</>,
                },
                {
                  label: <>寝具</>,
                  value: <>セミダブルベッド4台　ふとん4組（5名よりご用意）</>,
                },
                {
                  label: <>設備</>,
                  value: <>エアコン、空気清浄機、洗濯機、床暖房</>,
                },
                {
                  label: <>テレビ</>,
                  value: <>あり（19インチ ポータブルタイプ）<br />※インターネット、機器等との接続不可</>,
                },
                {
                  label: <>スピーカー</>,
                  value: <>Bluetoothスピーカー</>,
                },
                {
                  label: <>Wi-Fi</>,
                  value: <>無料Wi-Fi（無線LAN）</>,
                },
                {
                  label: <>禁煙</>,
                  value: <>建物・敷地内　全面禁煙</>,
                },
              ]} />
            </section>
          </div>
          <div id="facility_dGallery" className="facility_dSection">
            <ImgList data={[
              '/images/facility/b03af57be99c50245b91082cd2f022ba.jpg',
              '/images/facility/20220611nishi.jpg',
              '/images/facility/20220611nishimina3.jpg',
              '/images/facility/20220611nishiwa.jpg',
              '/images/facility/20220611nishi1.jpg',
              '/images/facility/wacoal02.jpg',
              '/images/facility/d25a3e71687462a7faebce4ef8f53a8d.jpg',
              '/images/facility/wacoal06.jpg',
              '/images/facility/84ea266198eb44d17b1307c8ee10e540.jpg',
            ]} />
          </div>
          <section id="facility_dIntro" className="facility_d facility_dSection">
            <h2 className="facility_dTit">未来へ継ぐ「住まい」としての京町家</h2>
            <p>「京の温所 西陣別邸」は、明治後期に西陣織の商家がお客様をおもてなしする屋敷として建てた京町家です。ゆったりと広く、優雅な佇まいの建物は、見えないところや細部まで丁寧につくられ、手入れされながら大切に受け継がれてきました。<br />そんなつくり手や受け継いできた人たちの思いを大切にし、現代生活に寄り添う快適さと、時を重ねてきた建物の魅力が引き立つよう現代的なエッセンスを加え、未来へ継いでゆく「住まい」としてリノベーションしています。<br />例えば、ここちよい風が通り、天窓からの光が注ぐ「通り庭」に位置するダイニング＆リビングは、足裏に気持ちいい炭モルタルの床を設え、夏はひんやりと冬は床暖房で温かく快適に過ごせます。また、芸舞妓が舞った二間続きの和室は中庭をデッキスペースにすることで、新たな印象をかもし出すくつろぎの空間となっています。北欧ヴィンテージの家具には、「ミナ ペルホネン」のテキスタイルをあしらいました。<br />懐かしさと新しさが交差する、心地よい住空間でゆっくりとお過ごしください。
            </p>
            <ImgList data={[
              '/images/facility/96b3dead508561db87d763a12f5f00cf.jpg',
              '/images/facility/wacoal01.jpg',
              '/images/facility/914a51c8682d83b8bbaf338fa5c62a57.jpg',
            ]} />
          </section>
          <section id="facility_dKitchen" className="facility_d facility_dSection">
            <div>
              <h2 className="facility_dTit">京都の食材を選び、つくり、食べる。という贅沢な体験</h2>
              <p>歩いて行ける店もいいけれど、早起きして自動車で美山や大原の朝市へ。「あれもこれも美味しそう！」
                とワクワクしながら新鮮な食材をたくさん買い込んで、さて、何をつくろう?<br />「住まい」としてリノベーションしている「京の温所
                西陣別邸」のキッチンは、大型冷蔵庫、三口コンロ、グリルにオーブンレンジを備えています。また、料理好きの皆川明がセレクトした使ってみたくなる用と美を兼ね備えた調理器具や食器、カトラリーをはじめ、美味しい料理には欠かせないワインのためのワインセラーもご用意。「ミナ ペルホネン」のテキスタイルの特別なテーブルマットとコースターもご使用いただけます。京都の食材を選び、つくり、食べる。という贅沢な体験ができる宿です。
              </p>
            </div>
            <ImgList data={[
              '/images/facility/43d50cfa9fdb99625d4d12c07ee3f4bb.jpg',
              '/images/facility/278dc5958f80a08c9e19ea8a401815fb.jpg',
              '/images/facility/ada4c220d300d93b26be77b28e071f08.jpg',
            ]} />
            <KitchenList />
          </section>
          <section id="facility_dFeature" className="facility_d facility_dSection">
            <h2 className="facility_dTit">光を感じるバスタイムとやさしく包み込まれるようなベッドルーム</h2>
            <p>「京の温所 西陣別邸」には滞在される方に「ちょっぴり非日常的でステキな体験がプレゼントできたら…」という、建築家
              中村好文の思いがそこここにちりばめられています。<br />1階には中庭を眺めながらゆったりと浸かれる青森ヒバの風呂、そして2階には太陽の光が降り注ぐシャワーブースが。光を感じる特別なバスタイムをお過ごしください。<br />また、2つあるベッドルームに使われているのは「ミナ
              ペルホネン」のテキスタイル。やわらかな印象の聚楽塗の天井と共に、やさしく包み込まれるようなベッドルームでぐっすりとお休みください。</p>
            <ImgList data={[
              '/images/facility/41898f251892518d30b93e4105cac0a5_1.jpg',
              '/images/facility/a812e0fe4bbe05eaca16df046d207545_1.jpg',
              '/images/facility/8d961bc5cb60dae0e209763ff94435c3.jpg',
            ]} />
          </section>
          <AmenitieList data={[
            '/images/facility/572d4fe208ae296f3253e75daffe8d3d.jpg',
            '/images/facility/amenities_bath.jpg',
            '/images/facility/amenities_pajamas.jpg',
          ]} />
          <section id="facility_dAccess">
            <Access data={{
              train: <>地下鉄烏丸線「今出川」駅4番出口　徒歩約19分</>,
              bus: <>「今出川浄福寺」下車　徒歩約1分<br />「千本今出川」下車　徒歩約3分</>,
              taxi: <>「京都」駅より約20分<br />地下鉄烏丸線「今出川」駅より約5分</>,
              parking: <>敷地内専用ガレージ　2台駐車可<br />※普通乗用車・ミニバン・SUVなど／高さ制限：2.0m以下</>,
              img: "/images/facility_d/nishijin/nishijin_garage.jpg",
              maplink: "https://goo.gl/maps/39rECvwSFwV3Cam1A",
              map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d408.38841794664444!2d135.7454428!3d35.0290444!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600107a488142de1%3A0xe4faa09635a4284!2z5Lqs44Gu5rip5omAIOilv-mZo-WIpemCuCAjNQ!5e0!3m2!1sja!2sjp!4v1570593222673!5m2!1sja!2sjp",
            }} />
          </section>
          <section id="facility_dPlan">
            <PlanBnr roomcd="0000001669" />
          </section>
          <FaqList />
        </div>
      </main>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
